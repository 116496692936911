export const HSRPFHisto = [
  {
    slug: 'acheron',
    score: 31950,
    usage: 44.12,
    role: 'dps',
    new: false
  },
  {
    slug: 'aglaea',
    score: 31420,
    usage: 9.06,
    role: 'dps',
    new: false
  },
  {
    slug: 'argenti',
    score: 35236,
    usage: 7.31,
    role: 'dps',
    new: false
  },
  {
    slug: 'arlan',
    score: 22000,
    usage: 0.02,
    role: 'dps',
    new: false
  },
  {
    slug: 'asta',
    score: 26856,
    usage: 0.24,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'aventurine',
    score: 33875,
    usage: 51.07,
    role: 'sustain',
    new: false
  },
  {
    slug: 'bailu',
    score: 30856,
    usage: 0.67,
    role: 'sustain',
    new: false
  },
  {
    slug: 'black-swan',
    score: 28600,
    usage: 11.91,
    role: 'specialist',
    new: false
  },
  {
    slug: 'blade',
    score: 28391,
    usage: 0.57,
    role: 'dps',
    new: false
  },
  {
    slug: 'boothill',
    score: 22000,
    usage: 0.12,
    role: 'dps',
    new: false
  },
  {
    slug: 'bronya',
    score: 30423,
    usage: 4.09,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'clara',
    score: 27720,
    usage: 2.59,
    role: 'dps',
    new: false
  },
  {
    slug: 'dan-heng',
    score: 22000,
    usage: 0.01,
    role: 'dps',
    new: false
  },
  {
    slug: 'dr-ratio',
    score: 22000,
    usage: 0.07,
    role: 'dps',
    new: false
  },
  {
    slug: 'feixiao',
    score: 28553,
    usage: 3.57,
    role: 'dps',
    new: false
  },
  {
    slug: 'firefly',
    score: 26993,
    usage: 19.39,
    role: 'dps',
    new: false
  },
  {
    slug: 'fu-xuan',
    score: 32386,
    usage: 24.46,
    role: 'sustain',
    new: false
  },
  {
    slug: 'gallagher',
    score: 32640,
    usage: 28.34,
    role: 'sustain',
    new: false
  },
  {
    slug: 'gepard',
    score: 28548,
    usage: 1.37,
    role: 'sustain',
    new: false
  },
  {
    slug: 'guinaifen',
    score: 26448,
    usage: 0.48,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'hanya',
    score: 22000,
    usage: 0.1,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'herta',
    score: 35273,
    usage: 62.53,
    role: 'specialist',
    new: false
  },
  {
    slug: 'himeko',
    score: 29517,
    usage: 24.7,
    role: 'dps',
    new: false
  },
  {
    slug: 'hook',
    score: 22000,
    usage: 0.01,
    role: 'dps',
    new: false
  },
  {
    slug: 'huohuo',
    score: 33705,
    usage: 25.72,
    role: 'sustain',
    new: false
  },
  {
    slug: 'imbibitor-lunae',
    score: 22000,
    usage: 0.72,
    role: 'dps',
    new: false
  },
  {
    slug: 'jade',
    score: 38165,
    usage: 23.81,
    role: 'specialist',
    new: false
  },
  {
    slug: 'jiaoqiu',
    score: 34868,
    usage: 23.32,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'jing-yuan',
    score: 31682,
    usage: 16.16,
    role: 'dps',
    new: false
  },
  {
    slug: 'jingliu',
    score: 24214,
    usage: 0.62,
    role: 'dps',
    new: false
  },
  {
    slug: 'kafka',
    score: 29248,
    usage: 13.62,
    role: 'dps',
    new: false
  },
  {
    slug: 'lingsha',
    score: 34916,
    usage: 37.1,
    role: 'sustain',
    new: false
  },
  {
    slug: 'luka',
    score: 22000,
    usage: 0.0,
    role: 'dps',
    new: false
  },
  {
    slug: 'luocha',
    score: 32504,
    usage: 8.69,
    role: 'sustain',
    new: false
  },
  {
    slug: 'lynx',
    score: 28513,
    usage: 0.42,
    role: 'sustain',
    new: false
  },
  {
    slug: 'march-7th',
    score: 28984,
    usage: 0.29,
    role: 'sustain',
    new: false
  },
  {
    slug: 'march-7th-swordmaster',
    score: 25951,
    usage: 0.87,
    role: 'specialist',
    new: false
  },
  {
    slug: 'misha',
    score: 22000,
    usage: 0.02,
    role: 'dps',
    new: false
  },
  {
    slug: 'moze',
    score: 22000,
    usage: 0.12,
    role: 'specialist',
    new: false
  },
  {
    slug: 'mydei',
    score: 26934,
    usage: 2.83,
    role: 'dps',
    new: true
  },
  {
    slug: 'natasha',
    score: 22000,
    usage: 0.07,
    role: 'sustain',
    new: false
  },
  {
    slug: 'pela',
    score: 31816,
    usage: 16.89,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'qingque',
    score: 24381,
    usage: 0.2,
    role: 'dps',
    new: false
  },
  {
    slug: 'rappa',
    score: 31928,
    usage: 12.51,
    role: 'dps',
    new: false
  },
  {
    slug: 'robin',
    score: 33141,
    usage: 52.2,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'ruan-mei',
    score: 31465,
    usage: 52.18,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sampo',
    score: 22000,
    usage: 0.08,
    role: 'specialist',
    new: false
  },
  {
    slug: 'seele',
    score: 22000,
    usage: 0.28,
    role: 'dps',
    new: false
  },
  {
    slug: 'serval',
    score: 33459,
    usage: 4.54,
    role: 'specialist',
    new: false
  },
  {
    slug: 'silver-wolf',
    score: 26282,
    usage: 2.31,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sparkle',
    score: 30573,
    usage: 17.66,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sunday',
    score: 33105,
    usage: 39.68,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sushang',
    score: 22000,
    usage: 0.0,
    role: 'dps',
    new: false
  },
  {
    slug: 'the-herta',
    score: 38524,
    usage: 71.52,
    role: 'dps',
    new: false
  },
  {
    slug: 'tingyun',
    score: 30642,
    usage: 5.14,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'tingyun-fugue',
    score: 32747,
    usage: 24.71,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'topaz',
    score: 25645,
    usage: 1.32,
    role: 'specialist',
    new: false
  },
  {
    slug: 'trailblazer-destruction',
    score: 22000,
    usage: 0.0,
    role: 'dps',
    new: false
  },
  {
    slug: 'trailblazer-harmony',
    score: 29328,
    usage: 19.36,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'trailblazer-preservation',
    score: 22000,
    usage: 0.07,
    role: 'sustain',
    new: false
  },
  {
    slug: 'trailblazer-remembrance',
    score: 37070,
    usage: 41.01,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'tribbie',
    score: 39623,
    usage: 36.23,
    role: 'specialist',
    new: false
  },
  {
    slug: 'welt',
    score: 24651,
    usage: 0.48,
    role: 'specialist',
    new: false
  },
  {
    slug: 'xueyi',
    score: 26319,
    usage: 0.16,
    role: 'dps',
    new: false
  },
  {
    slug: 'yanqing',
    score: 22000,
    usage: 0.0,
    role: 'dps',
    new: false
  },
  {
    slug: 'yukong',
    score: 22000,
    usage: 0.01,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'yunli',
    score: 30277,
    usage: 5.27,
    role: 'dps',
    new: false
  }
];
