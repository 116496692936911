export const PFCurrentStage4Side1 = [
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 4.92,
    rank: 1,
    avg_round: 33120,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 4.43,
    rank: 2,
    avg_round: 24792,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 3.2,
    rank: 3,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 2.93,
    rank: 4,
    avg_round: 36187,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 2.73,
    rank: 5,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 2.15,
    rank: 6,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 2.1,
    rank: 7,
    avg_round: 38200,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 2.03,
    rank: 8,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 1.9,
    rank: 9,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.81,
    rank: 10,
    avg_round: 29193,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 1.79,
    rank: 11,
    avg_round: 39965,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 1.64,
    rank: 12,
    avg_round: 37778,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 1.44,
    rank: 13,
    avg_round: 39999,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 1.42,
    rank: 14,
    avg_round: 35176,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 1.39,
    rank: 15,
    avg_round: 39749,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 1.35,
    rank: 16,
    avg_round: 24352,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 1.3,
    rank: 17,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 1.3,
    rank: 17,
    avg_round: 37725,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.19,
    rank: 19,
    avg_round: 25121,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 1.1,
    rank: 20,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 1.06,
    rank: 21,
    avg_round: 28903,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 1.04,
    rank: 22,
    avg_round: 26633,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 1.03,
    rank: 23,
    avg_round: 29109,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.94,
    rank: 24,
    avg_round: 34195,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.89,
    rank: 25,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.8,
    rank: 26,
    avg_round: 21851,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.8,
    rank: 26,
    avg_round: 39353,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.76,
    rank: 28,
    avg_round: 36617,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.75,
    rank: 29,
    avg_round: 21267,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.73,
    rank: 30,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.66,
    rank: 31,
    avg_round: 32312,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.65,
    rank: 32,
    avg_round: 39797,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.63,
    rank: 33,
    avg_round: 34843,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.61,
    rank: 34,
    avg_round: 37150,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.6,
    rank: 35,
    avg_round: 38668,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.59,
    rank: 36,
    avg_round: 37710,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.55,
    rank: 37,
    avg_round: 33032,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.49,
    rank: 38,
    avg_round: 39745,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.48,
    rank: 39,
    avg_round: 33410,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.42,
    rank: 40,
    avg_round: 31047,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.42,
    rank: 40,
    avg_round: 22517,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.41,
    rank: 42,
    avg_round: 39877,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.39,
    rank: 43,
    avg_round: 31872,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.36,
    rank: 44,
    avg_round: 38468,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.36,
    rank: 44,
    avg_round: 25844,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.36,
    rank: 44,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.36,
    rank: 44,
    avg_round: 30616,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.36,
    rank: 44,
    avg_round: 26436,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.36,
    rank: 44,
    avg_round: 35592,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.34,
    rank: 50,
    avg_round: 35755,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.34,
    rank: 50,
    avg_round: 39542,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.33,
    rank: 52,
    avg_round: 37614,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.32,
    rank: 53,
    avg_round: 34179,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.31,
    rank: 54,
    avg_round: 23073,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.3,
    rank: 55,
    avg_round: 34758,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.29,
    rank: 56,
    avg_round: 38569,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.29,
    rank: 56,
    avg_round: 34527,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.29,
    rank: 56,
    avg_round: 39631,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.29,
    rank: 56,
    avg_round: 26207,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 60,
    avg_round: 21520,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 61,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.26,
    rank: 61,
    avg_round: 27815,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.26,
    rank: 61,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.25,
    rank: 64,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.24,
    rank: 65,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.24,
    rank: 65,
    avg_round: 31478,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.24,
    rank: 65,
    avg_round: 29744,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.24,
    rank: 65,
    avg_round: 37925,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.23,
    rank: 69,
    avg_round: 26018,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.22,
    rank: 70,
    avg_round: 25087,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.22,
    rank: 70,
    avg_round: 36902,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 72,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.21,
    rank: 72,
    avg_round: 39188,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.21,
    rank: 72,
    avg_round: 36437,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.21,
    rank: 72,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.19,
    rank: 76,
    avg_round: 39387,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.19,
    rank: 76,
    avg_round: 32800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.19,
    rank: 76,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.19,
    rank: 76,
    avg_round: 36875,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 76,
    avg_round: 20900,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.19,
    rank: 76,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.18,
    rank: 82,
    avg_round: 22320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.17,
    rank: 83,
    avg_round: 33380,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 84,
    avg_round: 22911,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 84,
    avg_round: 31556,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.16,
    rank: 84,
    avg_round: 27332,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 84,
    avg_round: 23859,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 84,
    avg_round: 25249,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.16,
    rank: 84,
    avg_round: 33200,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 84,
    avg_round: 27027,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 91,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 91,
    avg_round: 34708,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.15,
    rank: 91,
    avg_round: 27383,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 91,
    avg_round: 25605,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 91,
    avg_round: 37027,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'tribbie',
    app_rate: 0.15,
    rank: 91,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.15,
    rank: 91,
    avg_round: 38743,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.15,
    rank: 91,
    avg_round: 37880,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 91,
    avg_round: 23230,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 91,
    avg_round: 35620,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 91,
    avg_round: 33236,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 91,
    avg_round: 37004,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 91,
    avg_round: 25712,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 91,
    avg_round: 32644,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 105,
    avg_round: 38980,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 105,
    avg_round: 30255,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 105,
    avg_round: 31051,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 105,
    avg_round: 34760,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 109,
    avg_round: 30173,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 109,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.13,
    rank: 109,
    avg_round: 28855,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.13,
    rank: 109,
    avg_round: 36767,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.13,
    rank: 109,
    avg_round: 36168,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 109,
    avg_round: 22635,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 109,
    avg_round: 36695,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 116,
    avg_round: 24580,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 116,
    avg_round: 34333,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 116,
    avg_round: 29048,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 116,
    avg_round: 39500,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 116,
    avg_round: 37263,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.12,
    rank: 116,
    avg_round: 28600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.12,
    rank: 116,
    avg_round: 33053,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 116,
    avg_round: 31707,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 116,
    avg_round: 21820,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.12,
    rank: 116,
    avg_round: 30348,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.12,
    rank: 116,
    avg_round: 30987,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.12,
    rank: 116,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 116,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.11,
    rank: 129,
    avg_round: 28945,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 129,
    avg_round: 28600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 129,
    avg_round: 30931,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 129,
    avg_round: 27610,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 129,
    avg_round: 26980,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 129,
    avg_round: 25573,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 129,
    avg_round: 33016,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 129,
    avg_round: 37129,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 137,
    avg_round: 32240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.1,
    rank: 137,
    avg_round: 36453,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 137,
    avg_round: 39832,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 137,
    avg_round: 32933,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'pela',
    app_rate: 0.1,
    rank: 137,
    avg_round: 38325,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.1,
    rank: 137,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 143,
    avg_round: 37925,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 143,
    avg_round: 26700,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 143,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 143,
    avg_round: 24480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.09,
    rank: 143,
    avg_round: 38413,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 143,
    avg_round: 29420,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 143,
    avg_round: 36664,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 143,
    avg_round: 33658,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 143,
    avg_round: 25447,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 152,
    avg_round: 24380,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 152,
    avg_round: 36460,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 152,
    avg_round: 25992,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 152,
    avg_round: 34120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 152,
    avg_round: 31767,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 152,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 152,
    avg_round: 35693,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 152,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 152,
    avg_round: 32893,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 152,
    avg_round: 22760,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 152,
    avg_round: 37160,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.08,
    rank: 152,
    avg_round: 28777,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 152,
    avg_round: 36173,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 152,
    avg_round: 33640,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 152,
    avg_round: 32608,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 152,
    avg_round: 35850,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 168,
    avg_round: 30192,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 168,
    avg_round: 39340,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 168,
    avg_round: 36920,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 168,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.07,
    rank: 168,
    avg_round: 35691,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 168,
    avg_round: 25540,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 168,
    avg_round: 24967,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 168,
    avg_round: 32827,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 168,
    avg_round: 37530,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.07,
    rank: 168,
    avg_round: 23947,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 168,
    avg_round: 37400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 168,
    avg_round: 31293,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 168,
    avg_round: 35520,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 168,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.07,
    rank: 168,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.06,
    rank: 183,
    avg_round: 30280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 27440,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 26660,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 183,
    avg_round: 28360,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 32160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 183,
    avg_round: 20280,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.06,
    rank: 183,
    avg_round: 37693,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 183,
    avg_round: 30120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'serval',
    char_four: 'tribbie',
    app_rate: 0.06,
    rank: 183,
    avg_round: 39800,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 183,
    avg_round: 23120,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 183,
    avg_round: 22960,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 183,
    avg_round: 37387,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 183,
    avg_round: 28493,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 183,
    avg_round: 29740,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 183,
    avg_round: 29440,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 205,
    avg_round: 26480,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 205,
    avg_round: 36120,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 22352,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 205,
    avg_round: 28547,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 205,
    avg_round: 38448,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 30032,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 35610,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 205,
    avg_round: 23813,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 205,
    avg_round: 37600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 205,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 205,
    avg_round: 36130,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 205,
    avg_round: 23552,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 205,
    avg_round: 28400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 205,
    avg_round: 27480,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 205,
    avg_round: 36940,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 25960,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 205,
    avg_round: 36267,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 33187,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 205,
    avg_round: 30300,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 205,
    avg_round: 34040,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 205,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 205,
    avg_round: 37860,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 31210,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 205,
    avg_round: 30093,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 205,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 23030,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 205,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 31120,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 25933,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 33180,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 25440,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 205,
    avg_round: 23520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 30930,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 205,
    avg_round: 21680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 34120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 33260,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 27990,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 33960,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 205,
    avg_round: 22730,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 26360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 205,
    avg_round: 38720,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 205,
    avg_round: 28380,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 205,
    avg_round: 23170,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 205,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 205,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 34220,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 31413,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 205,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 205,
    avg_round: 33690,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 205,
    avg_round: 22880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 265,
    avg_round: 26640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.04,
    rank: 265,
    avg_round: 37093,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 265,
    avg_round: 34870,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 35200,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 265,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 265,
    avg_round: 37260,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 25560,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 32533,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 36987,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 35880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 265,
    avg_round: 27820,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 265,
    avg_round: 32120,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 25947,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 265,
    avg_round: 26840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.04,
    rank: 265,
    avg_round: 32424,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.04,
    rank: 265,
    avg_round: 34620,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 265,
    avg_round: 35773,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 265,
    avg_round: 31030,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 23720,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 32800,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 24480,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 34733,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 36464,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 28733,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 23380,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.04,
    rank: 265,
    avg_round: 38120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 29500,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 36933,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 26400,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 39200,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 265,
    avg_round: 37100,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 23507,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 39000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 265,
    avg_round: 21840,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.04,
    rank: 265,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 31800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 34520,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 31280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 34840,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 30100,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 265,
    avg_round: 29400,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 265,
    avg_round: 30307,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 265,
    avg_round: 38280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 265,
    avg_round: 33787,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 265,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 27520,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 20520,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 319,
    avg_round: 39707,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 27240,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 319,
    avg_round: 25240,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 319,
    avg_round: 32800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 30533,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 36080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 319,
    avg_round: 32253,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 319,
    avg_round: 25267,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 24507,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 26000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 34420,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 319,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 319,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 319,
    avg_round: 22700,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 24880,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 38860,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 30680,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 21400,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.03,
    rank: 319,
    avg_round: 37590,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 319,
    avg_round: 21480,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 23240,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 24020,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 319,
    avg_round: 38720,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 29427,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 22400,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 319,
    avg_round: 26520,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 319,
    avg_round: 22880,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 30800,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 31253,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 22620,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 319,
    avg_round: 28760,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.03,
    rank: 319,
    avg_round: 23740,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 24867,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 30340,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 22120,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 28180,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 319,
    avg_round: 33213,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 319,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 33520,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 319,
    avg_round: 36400,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 319,
    avg_round: 25800,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 319,
    avg_round: 22080,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 29693,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 319,
    avg_round: 37160,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 319,
    avg_round: 36400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 32380,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21760,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26960,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28880,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26880,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34480,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27520,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28080,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'tingyun-fugue',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30320,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 31760,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27160,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 37320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'pela',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36940,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 20480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 32000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34480,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 32400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36280,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34200,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 22000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24300,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 39560,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21980,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25860,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30440,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21920,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25560,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36980,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21920,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 31200,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24280,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28720,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 38120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 35520,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29240,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 22200,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27760,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 20560,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30160,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25640,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23600,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34520,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30900,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29260,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28680,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26120,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36260,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29880,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'tingyun-fugue',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 22120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27440,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27600,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 32040,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24240,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 39900,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 20400,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27240,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27480,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29040,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27800,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21160,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29820,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30373,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36300,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 383,
    avg_round: 33240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29220,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 33600,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23400,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 35200,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23720,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26400,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30180,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24180,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 31120,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28240,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25960,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'topaz',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 32040,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29300,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21800,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27560,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 37680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 39960,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 37860,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 32880,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 383,
    avg_round: 33080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 33560,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27940,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34680,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23600,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 31240,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 38480,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 383,
    avg_round: 31933,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27500,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 33840,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24860,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26140,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30760,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 39400,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 31200,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21960,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30440,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25720,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 37187,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30240,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 39507,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29400,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30080,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 35800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30040,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34880,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34360,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24640,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26240,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34620,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 37240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.02,
    rank: 383,
    avg_round: 22640,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'ruan-mei',
    char_three: 'tingyun',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29160,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 31813,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28420,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23540,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29480,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23440,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 23267,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 30160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 21600,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 26040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29600,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29120,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 383,
    avg_round: 38540,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27020,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 34320,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 39100,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 383,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 383,
    avg_round: 24520,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'jiaoqiu',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 383,
    avg_round: 29520,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 383,
    avg_round: 25000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.02,
    rank: 383,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'rappa',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 383,
    avg_round: 37800,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 383,
    avg_round: 36880,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 383,
    avg_round: 35020,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 383,
    avg_round: 28000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 383,
    avg_round: 27460,
    star_num: '4'
  }
];
