export const PFCurrentStage4Side2 = [
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 3.31,
    rank: 1,
    avg_round: 29571,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 3.13,
    rank: 2,
    avg_round: 30646,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 2.78,
    rank: 3,
    avg_round: 28017,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 2.13,
    rank: 4,
    avg_round: 30375,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 1.68,
    rank: 5,
    avg_round: 29459,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 1.59,
    rank: 6,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 1.47,
    rank: 7,
    avg_round: 27090,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 1.47,
    rank: 7,
    avg_round: 35433,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 1.24,
    rank: 9,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 1.15,
    rank: 10,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 1.08,
    rank: 11,
    avg_round: 39968,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 1.04,
    rank: 12,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 1.01,
    rank: 13,
    avg_round: 27370,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.93,
    rank: 14,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.87,
    rank: 15,
    avg_round: 35614,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.86,
    rank: 16,
    avg_round: 29407,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.85,
    rank: 17,
    avg_round: 24736,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.83,
    rank: 18,
    avg_round: 29424,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.83,
    rank: 18,
    avg_round: 39330,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.83,
    rank: 18,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.8,
    rank: 21,
    avg_round: 36125,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.8,
    rank: 21,
    avg_round: 34106,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.74,
    rank: 23,
    avg_round: 27146,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.73,
    rank: 24,
    avg_round: 24314,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.72,
    rank: 25,
    avg_round: 33486,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.71,
    rank: 26,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.7,
    rank: 27,
    avg_round: 39990,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.7,
    rank: 27,
    avg_round: 29645,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.66,
    rank: 29,
    avg_round: 24507,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.66,
    rank: 29,
    avg_round: 25465,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.62,
    rank: 31,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.59,
    rank: 32,
    avg_round: 27029,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.57,
    rank: 33,
    avg_round: 31279,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.57,
    rank: 33,
    avg_round: 32951,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.55,
    rank: 35,
    avg_round: 23032,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.54,
    rank: 36,
    avg_round: 38834,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.51,
    rank: 37,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.49,
    rank: 38,
    avg_round: 28962,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.49,
    rank: 38,
    avg_round: 31134,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.49,
    rank: 38,
    avg_round: 38740,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.46,
    rank: 41,
    avg_round: 32884,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.46,
    rank: 41,
    avg_round: 39278,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.45,
    rank: 43,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.42,
    rank: 44,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.41,
    rank: 45,
    avg_round: 22490,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.39,
    rank: 46,
    avg_round: 32669,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.39,
    rank: 46,
    avg_round: 38928,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.39,
    rank: 46,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.37,
    rank: 49,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.36,
    rank: 50,
    avg_round: 31144,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.36,
    rank: 50,
    avg_round: 27813,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.36,
    rank: 50,
    avg_round: 36498,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.36,
    rank: 50,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.36,
    rank: 50,
    avg_round: 28707,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.35,
    rank: 55,
    avg_round: 23893,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.33,
    rank: 56,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.33,
    rank: 56,
    avg_round: 34027,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.33,
    rank: 56,
    avg_round: 39967,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.32,
    rank: 59,
    avg_round: 38120,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.31,
    rank: 60,
    avg_round: 32948,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.3,
    rank: 61,
    avg_round: 28290,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.29,
    rank: 62,
    avg_round: 33687,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.29,
    rank: 62,
    avg_round: 30268,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.29,
    rank: 62,
    avg_round: 21400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.29,
    rank: 62,
    avg_round: 24427,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.28,
    rank: 66,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.28,
    rank: 66,
    avg_round: 22250,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'huohuo',
    app_rate: 0.27,
    rank: 68,
    avg_round: 23364,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 68,
    avg_round: 28500,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.26,
    rank: 70,
    avg_round: 37541,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.25,
    rank: 71,
    avg_round: 24110,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 71,
    avg_round: 38085,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 71,
    avg_round: 37834,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.25,
    rank: 71,
    avg_round: 33342,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.24,
    rank: 75,
    avg_round: 35360,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 76,
    avg_round: 39716,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 76,
    avg_round: 38040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.22,
    rank: 76,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.22,
    rank: 76,
    avg_round: 34997,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.21,
    rank: 80,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 80,
    avg_round: 39987,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.21,
    rank: 80,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.21,
    rank: 80,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.2,
    rank: 84,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 84,
    avg_round: 23846,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.2,
    rank: 84,
    avg_round: 38105,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.2,
    rank: 84,
    avg_round: 39235,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.19,
    rank: 88,
    avg_round: 25735,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 88,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.19,
    rank: 88,
    avg_round: 36194,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 88,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.19,
    rank: 88,
    avg_round: 29442,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.19,
    rank: 88,
    avg_round: 34964,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.19,
    rank: 88,
    avg_round: 25051,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 88,
    avg_round: 32448,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.19,
    rank: 88,
    avg_round: 38889,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.18,
    rank: 97,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.18,
    rank: 97,
    avg_round: 39855,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 97,
    avg_round: 24093,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.18,
    rank: 97,
    avg_round: 35545,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.18,
    rank: 97,
    avg_round: 33996,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 97,
    avg_round: 25118,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.18,
    rank: 97,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.18,
    rank: 97,
    avg_round: 29288,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 105,
    avg_round: 34720,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 105,
    avg_round: 31016,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.17,
    rank: 105,
    avg_round: 29537,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.17,
    rank: 105,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.17,
    rank: 105,
    avg_round: 25960,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 105,
    avg_round: 24220,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.17,
    rank: 105,
    avg_round: 31991,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 112,
    avg_round: 39780,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 112,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.16,
    rank: 112,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 112,
    avg_round: 29834,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 116,
    avg_round: 23218,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 116,
    avg_round: 36180,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 116,
    avg_round: 37420,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 116,
    avg_round: 37196,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.15,
    rank: 116,
    avg_round: 27807,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 116,
    avg_round: 26080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 116,
    avg_round: 35709,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 116,
    avg_round: 34270,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 116,
    avg_round: 38280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.15,
    rank: 116,
    avg_round: 30273,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 116,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.15,
    rank: 116,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 116,
    avg_round: 28280,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 116,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.15,
    rank: 116,
    avg_round: 38853,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 116,
    avg_round: 34886,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 116,
    avg_round: 21440,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 133,
    avg_round: 28125,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 133,
    avg_round: 28836,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 133,
    avg_round: 33280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 133,
    avg_round: 39733,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.14,
    rank: 133,
    avg_round: 36813,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 133,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 139,
    avg_round: 24920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.13,
    rank: 139,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 139,
    avg_round: 37977,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.13,
    rank: 139,
    avg_round: 27920,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 139,
    avg_round: 31333,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.13,
    rank: 139,
    avg_round: 34880,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 139,
    avg_round: 30870,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.13,
    rank: 139,
    avg_round: 39567,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 139,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 148,
    avg_round: 38067,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.12,
    rank: 148,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 148,
    avg_round: 33569,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 148,
    avg_round: 25848,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 148,
    avg_round: 26993,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 148,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 148,
    avg_round: 27713,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 148,
    avg_round: 31245,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 148,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.12,
    rank: 148,
    avg_round: 39864,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 148,
    avg_round: 24128,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 148,
    avg_round: 33071,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 148,
    avg_round: 28300,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 148,
    avg_round: 26307,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.12,
    rank: 148,
    avg_round: 39840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 148,
    avg_round: 39380,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.12,
    rank: 148,
    avg_round: 37072,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.12,
    rank: 148,
    avg_round: 37856,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 148,
    avg_round: 27905,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 167,
    avg_round: 28570,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 167,
    avg_round: 25144,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 167,
    avg_round: 23966,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 167,
    avg_round: 26413,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.11,
    rank: 167,
    avg_round: 24400,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 167,
    avg_round: 39440,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.11,
    rank: 167,
    avg_round: 23370,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 167,
    avg_round: 29103,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 167,
    avg_round: 34040,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.11,
    rank: 167,
    avg_round: 39568,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 167,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 167,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 179,
    avg_round: 33624,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 179,
    avg_round: 30240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 179,
    avg_round: 26920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.1,
    rank: 179,
    avg_round: 24800,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 179,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 179,
    avg_round: 28697,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.1,
    rank: 179,
    avg_round: 39880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.1,
    rank: 179,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 179,
    avg_round: 33133,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 179,
    avg_round: 28930,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 179,
    avg_round: 26944,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 190,
    avg_round: 30400,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 190,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 190,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 190,
    avg_round: 37133,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 190,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.09,
    rank: 190,
    avg_round: 37432,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 190,
    avg_round: 23356,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.09,
    rank: 190,
    avg_round: 37660,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 190,
    avg_round: 39760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.09,
    rank: 190,
    avg_round: 29303,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 190,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 190,
    avg_round: 34216,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 190,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 203,
    avg_round: 26808,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 203,
    avg_round: 31640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 39528,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 203,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 203,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 203,
    avg_round: 26000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 203,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 203,
    avg_round: 39096,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 203,
    avg_round: 35580,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 203,
    avg_round: 35360,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 203,
    avg_round: 36773,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 203,
    avg_round: 26980,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 28064,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 203,
    avg_round: 24090,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 203,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 203,
    avg_round: 32340,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 203,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 39920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 203,
    avg_round: 36600,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gepard',
    app_rate: 0.08,
    rank: 203,
    avg_round: 24577,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 37980,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 32620,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 203,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 203,
    avg_round: 39080,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'luocha',
    app_rate: 0.08,
    rank: 203,
    avg_round: 24310,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 203,
    avg_round: 24220,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 203,
    avg_round: 23067,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 203,
    avg_round: 28743,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 203,
    avg_round: 23907,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 234,
    avg_round: 27240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.07,
    rank: 234,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.07,
    rank: 234,
    avg_round: 39540,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 234,
    avg_round: 26950,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 234,
    avg_round: 25320,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.07,
    rank: 234,
    avg_round: 38640,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.07,
    rank: 234,
    avg_round: 34580,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 234,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 234,
    avg_round: 27160,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 234,
    avg_round: 26267,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 234,
    avg_round: 24027,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 234,
    avg_round: 24520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 234,
    avg_round: 30520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 234,
    avg_round: 24653,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 234,
    avg_round: 34690,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 249,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 249,
    avg_round: 27520,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 249,
    avg_round: 23773,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 249,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 249,
    avg_round: 35827,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 249,
    avg_round: 29240,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 249,
    avg_round: 25520,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 249,
    avg_round: 29347,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 249,
    avg_round: 27333,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 249,
    avg_round: 30960,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 249,
    avg_round: 26072,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 249,
    avg_round: 26360,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 249,
    avg_round: 26960,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 249,
    avg_round: 35880,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 249,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 249,
    avg_round: 24664,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 249,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 249,
    avg_round: 26840,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 249,
    avg_round: 33320,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 249,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 249,
    avg_round: 39472,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 249,
    avg_round: 31400,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.06,
    rank: 249,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 249,
    avg_round: 31533,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 249,
    avg_round: 32493,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 249,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 249,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 26840,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 39187,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 22253,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 27920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 30680,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 30580,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 30907,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 33800,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 27744,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 23000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 37800,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 35440,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 30440,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 34640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 22510,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 27580,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 25360,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 32040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 27170,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 30580,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 26333,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 34960,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 34180,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 27760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 37000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 38060,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 276,
    avg_round: 32400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 276,
    avg_round: 23533,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 32480,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 37880,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 38808,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 29853,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 36750,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 24760,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 24600,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 24920,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 31000,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 26920,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 27500,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'serval',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 32253,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 38440,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 38300,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 25180,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 24980,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 276,
    avg_round: 31080,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 28840,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 26750,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 39880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 31027,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 32580,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 26240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 276,
    avg_round: 25693,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 32507,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 30600,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 29413,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 276,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 276,
    avg_round: 37680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 276,
    avg_round: 34180,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 276,
    avg_round: 39147,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 276,
    avg_round: 23540,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 276,
    avg_round: 23560,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 355,
    avg_round: 38240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 27600,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 34140,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 355,
    avg_round: 26987,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 355,
    avg_round: 32500,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 36453,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 33330,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 32307,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 29300,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 355,
    avg_round: 27600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 37040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 36160,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 24640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 28540,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 22120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 355,
    avg_round: 25680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 355,
    avg_round: 28493,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 355,
    avg_round: 29427,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 31267,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 37280,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 355,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 21560,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'march-7th',
    app_rate: 0.04,
    rank: 355,
    avg_round: 30880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 26320,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 355,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 26940,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 355,
    avg_round: 39227,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 355,
    avg_round: 29060,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 33620,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 355,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 26220,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 355,
    avg_round: 26360,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'tribbie',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 355,
    avg_round: 29973,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 355,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 22480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 38240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 23640,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 29160,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 23347,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 25060,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 32460,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 409,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 409,
    avg_round: 30680,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 409,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 39640,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 30280,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 39700,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 32307,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 409,
    avg_round: 35360,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 409,
    avg_round: 24620,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 409,
    avg_round: 26040,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 36560,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 409,
    avg_round: 29020,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 24107,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 38480,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 31200,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 33900,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 36560,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 409,
    avg_round: 34360,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 31800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 409,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 409,
    avg_round: 39387,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 28040,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 33440,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 409,
    avg_round: 25200,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 29940,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 37020,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 26853,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 409,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 409,
    avg_round: 28740,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 32560,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 33140,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 409,
    avg_round: 38240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 39780,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 409,
    avg_round: 30040,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 409,
    avg_round: 30040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 409,
    avg_round: 36120,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 22340,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 409,
    avg_round: 30720,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 31740,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 38440,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 25360,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 409,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 409,
    avg_round: 39220,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 409,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'qingque',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 21160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38040,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39300,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37560,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 33760,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 22280,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29080,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28100,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28960,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29900,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31600,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25160,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 26020,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28680,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35640,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-remembrance',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29400,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 26360,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27180,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29040,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24800,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39733,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28440,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24140,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24680,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 21400,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25200,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 21080,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28120,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25200,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37900,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 23240,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24067,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37840,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25820,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29187,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31320,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28800,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30840,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 33760,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38860,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34920,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tingyun-fugue',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 23560,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38200,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 22560,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34300,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36400,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39080,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30080,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31440,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25200,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30200,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31340,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35620,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24800,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 20200,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 21520,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35620,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35840,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28520,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30840,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34820,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28320,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38720,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'trailblazer-harmony',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 21627,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28720,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'serval',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39200,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25867,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30520,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29840,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'yunli',
    char_three: 'tingyun-fugue',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27160,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30060,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32360,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30120,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25360,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27973,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39720,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25440,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28620,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25360,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35300,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29360,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24280,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29120,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29880,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25960,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'himeko',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 33200,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'kafka',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24160,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29080,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31760,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31787,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25360,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 26000,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25240,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27120,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35960,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39420,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35580,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34040,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34760,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'silver-wolf',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28560,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31040,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 33680,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 23560,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34880,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24580,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37260,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35860,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'herta',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36900,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32800,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24760,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24680,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28520,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34920,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32360,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37820,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32040,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32800,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35840,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27600,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30880,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39500,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 21720,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27920,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 23600,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29880,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29300,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37520,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29520,
    star_num: '4'
  },
  {
    char_one: 'himeko',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35720,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30840,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24800,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32320,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 33373,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'trailblazer-remembrance',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'guinaifen',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35600,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29200,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29280,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35840,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25280,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27320,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34680,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 26680,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36040,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24960,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27680,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27080,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 22640,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36240,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36120,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30800,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'bronya',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37600,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28640,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35520,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-remembrance',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38180,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'xueyi',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28920,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tingyun-fugue',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25300,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'asta',
    char_three: 'hanya',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25920,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30560,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39020,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27960,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38880,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24040,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30880,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32280,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36720,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'xueyi',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30480,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25080,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29720,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38920,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24840,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'herta',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25120,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31240,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31020,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24520,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36493,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36340,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39400,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24000,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 25893,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32860,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38813,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28320,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 35940,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 29000,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 474,
    avg_round: 22400,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28320,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 33960,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'himeko',
    char_three: 'herta',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30600,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 38460,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 474,
    avg_round: 31600,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 474,
    avg_round: 37680,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28880,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'mydei',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'tingyun-fugue',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 32440,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 24120,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 27480,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30580,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 474,
    avg_round: 33000,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34620,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'serval',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 36660,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 23160,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'acheron',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 26760,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 474,
    avg_round: 30600,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 474,
    avg_round: 28480,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 39080,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 34560,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 474,
    avg_round: 0,
    star_num: '4'
  }
];
