export const PFSupportedCharactersList = [
  {
    value: 'acheron',
    text: 'Acheron'
  },
  {
    value: 'aglaea',
    text: 'Aglaea'
  },
  {
    value: 'argenti',
    text: 'Argenti'
  },
  {
    value: 'arlan',
    text: 'Arlan'
  },
  {
    value: 'asta',
    text: 'Asta'
  },
  {
    value: 'aventurine',
    text: 'Aventurine'
  },
  {
    value: 'bailu',
    text: 'Bailu'
  },
  {
    value: 'black-swan',
    text: 'Black Swan'
  },
  {
    value: 'blade',
    text: 'Blade'
  },
  {
    value: 'boothill',
    text: 'Boothill'
  },
  {
    value: 'bronya',
    text: 'Bronya'
  },
  {
    value: 'clara',
    text: 'Clara'
  },
  {
    value: 'dan-heng',
    text: 'Dan Heng'
  },
  {
    value: 'dr-ratio',
    text: 'Dr. Ratio'
  },
  {
    value: 'feixiao',
    text: 'Feixiao'
  },
  {
    value: 'firefly',
    text: 'Firefly'
  },
  {
    value: 'fu-xuan',
    text: 'Fu Xuan'
  },
  {
    value: 'gallagher',
    text: 'Gallagher'
  },
  {
    value: 'gepard',
    text: 'Gepard'
  },
  {
    value: 'guinaifen',
    text: 'Guinaifen'
  },
  {
    value: 'hanya',
    text: 'Hanya'
  },
  {
    value: 'herta',
    text: 'Herta'
  },
  {
    value: 'himeko',
    text: 'Himeko'
  },
  {
    value: 'hook',
    text: 'Hook'
  },
  {
    value: 'huohuo',
    text: 'Huohuo'
  },
  {
    value: 'imbibitor-lunae',
    text: 'Imbibitor Lunae'
  },
  {
    value: 'jade',
    text: 'Jade'
  },
  {
    value: 'jiaoqiu',
    text: 'Jiaoqiu'
  },
  {
    value: 'jing-yuan',
    text: 'Jing Yuan'
  },
  {
    value: 'jingliu',
    text: 'Jingliu'
  },
  {
    value: 'kafka',
    text: 'Kafka'
  },
  {
    value: 'lingsha',
    text: 'Lingsha'
  },
  {
    value: 'luka',
    text: 'Luka'
  },
  {
    value: 'luocha',
    text: 'Luocha'
  },
  {
    value: 'lynx',
    text: 'Lynx'
  },
  {
    value: 'march-7th',
    text: 'March 7th'
  },
  {
    value: 'march-7th-swordmaster',
    text: 'March 7th - Hunt'
  },
  {
    value: 'misha',
    text: 'Misha'
  },
  {
    value: 'moze',
    text: 'Moze'
  },
  {
    value: 'mydei',
    text: 'Mydei'
  },
  {
    value: 'natasha',
    text: 'Natasha'
  },
  {
    value: 'pela',
    text: 'Pela'
  },
  {
    value: 'qingque',
    text: 'Qingque'
  },
  {
    value: 'rappa',
    text: 'Rappa'
  },
  {
    value: 'robin',
    text: 'Robin'
  },
  {
    value: 'ruan-mei',
    text: 'Ruan Mei'
  },
  {
    value: 'sampo',
    text: 'Sampo'
  },
  {
    value: 'seele',
    text: 'Seele'
  },
  {
    value: 'serval',
    text: 'Serval'
  },
  {
    value: 'silver-wolf',
    text: 'Silver Wolf'
  },
  {
    value: 'sparkle',
    text: 'Sparkle'
  },
  {
    value: 'sunday',
    text: 'Sunday'
  },
  {
    value: 'sushang',
    text: 'Sushang'
  },
  {
    value: 'the-herta',
    text: 'The Herta'
  },
  {
    value: 'tingyun',
    text: 'Tingyun'
  },
  {
    value: 'tingyun-fugue',
    text: 'Tingyun - Fugue'
  },
  {
    value: 'topaz',
    text: 'Topaz & Numby'
  },
  {
    value: 'trailblazer-preservation',
    text: 'Trailblazer (Fire)'
  },
  {
    value: 'trailblazer-destruction',
    text: 'Trailblazer (Physical)'
  },
  {
    value: 'trailblazer-harmony',
    text: 'Trailblazer (Imaginary)'
  },
  {
    value: 'trailblazer-remembrance',
    text: 'Trailblazer (Remembrance)'
  },
  {
    value: 'tribbie',
    text: 'Tribbie'
  },
  {
    value: 'welt',
    text: 'Welt'
  },
  {
    value: 'xueyi',
    text: 'Xueyi'
  },
  {
    value: 'yanqing',
    text: 'Yanqing'
  },
  {
    value: 'yukong',
    text: 'Yukong'
  },
  {
    value: 'yunli',
    text: 'Yunli'
  }
];
